import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"

class StaffShuttleForzaServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Forza Foods staff shuttle to and from Arlington Business Centre"
          description="View route information for the Forza Foods staff shuttle service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">Forza Foods staff shuttle service</h1>
              <p>Harehills Road - Forza Foods</p>
              <p>Forza Foods - Harehills Road</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>AM Route</h2>
                <iframe
                  title="Forza Foods staff shuttle AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1UgZA7_9pWUjK1t1TcPEqy4wqIXgl8N8&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>

                <p>
                  <strong>AM Towards</strong> Forza Foods
                </p>
                <p>
                  From Harehills Rd (BK Store), Harehills Rd (Top of Lambton View), Roundhay Road (St Aidan’s Church), Sovereign Street Coach Bays, Holbeck / Top Moor Side, Beeston Rd (After Cemetery Hill), Old Lane (Opp. Coop), Ring Road - Gypsy Lane, Ring Road - Middleton Park Road, Belle Isle WMC, Belle Isle Post Office, Morrisons - Hunslet, Forza.
                </p>
              </div>
              <div className="page-content__text">
                <h2>PM Route</h2>
                <iframe
                  title="Forza Foods staff shuttle PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=10-gR-P-a9iVWR578hiRa21AQT-Nxv5g&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>

                <p>
                  <strong>PM From</strong> Forza Foods
                </p>
                <p>
                  From Forza Foods, Morrisons Hunslet, Belle Isle Post Office, Belle Isle WMC, Ring Road - Middleton Park Rd, Ring Road - Gypsy Lane, Old Lane (Opp Coop), Beeston Rd (After Cemetery Hill), Holbeck Top Moor Side, Asda House Great Wilson Street, Roundhay Road (St Aidans Church), Harehills Road (Top of Lambton View), Harehills Road (BK Store)
                </p>
              </div>
            </div>
            <div className="page-content">
              <div style={{ width: '100%' }}>
                <h2>Timetable</h2>
                <div style={{ overflowX:'auto' }}>
                  <table className="timetable" style={{ width: '100%', fontSize: '0.9em' }}>
                    <thead>
                      <tr>
                        <th style={{ minWidth: 100 }}>Inbound Stop</th>
                        <th colSpan="6"><span className="mobile-hint">Swipe to see all journeys &#10142;</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Harehills Rd (BK Store)</td>
                        <td>05:15</td>
                        <td>13:15</td>
                        <td>17:15</td>
                      </tr>
                      <tr>
                        <td>Harehills Rd (Top of Lambton View)</td>
                        <td>05:18</td>
                        <td>13:18</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Roundhay Road (St Aidan’s Church)</td>
                        <td>05:21</td>
                        <td>13:21</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Sovereign Street Coach Bays</td>
                        <td>05:30</td>
                        <td>13:30</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Holbeck, Top Moor Side</td>
                        <td>05:35</td>
                        <td>13:35</td>
                        <td>17:33</td>
                      </tr>
                      <tr>
                        <td>Beeston Rd (After Cemetery Hill)</td>
                        <td>05:37</td>
                        <td>13:37</td>
                        <td>17:35</td>
                      </tr>
                      <tr>
                        <td>Old Lane (Opp. Coop)</td>
                        <td>05:40</td>
                        <td>13:40</td>
                        <td>17:40</td>
                      </tr>
                      <tr>
                        <td>Ring Road, Gypsy Lane</td>
                        <td>05:42</td>
                        <td>13:42</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Ring Road, Middleton Park Road</td>
                        <td>05:45</td>
                        <td>13:45</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Belle Isle WMC</td>
                        <td>05:48</td>
                        <td>13:48</td>
                        <td>17:50</td>
                      </tr>
                      <tr>
                        <td>Belle Isle Post Office</td>
                        <td>05:51</td>
                        <td>13:51</td>
                        <td>17:52</td>
                      </tr>
                      <tr>
                        <td>Morrisons, Hunslet</td>
                        <td>05:53</td>
                        <td>13:53</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Forza</td>
                        <td>06:15</td>
                        <td>14:51</td>
                        <td>18:15</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="timetable" style={{ width: '100%', fontSize: '0.9em' }}>
                    <thead>
                      <tr>
                        <th style={{ minWidth: 100 }}>Outbound Stop</th>
                        <th colSpan="6"><span className="mobile-hint">Swipe to see all journeys &#10142;</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Forza return journey</td>
                        <td>14:40</td>
                        <td>18:40</td>
                        <td>22:40</td>
                      </tr>
                      <tr>
                        <td>Morrisons, Hunslet</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Belle Isle Post Office</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Belle Isle WMC</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Ring Road, Middleton Park Road</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Ring Road, Gypsy Lane</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Old Lane (Opp. Coop)</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Beeston Rd (After Cemetery Hill)</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Holbeck, Top Moor Side</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Asda House, Great Wilson Street</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Roundhay Road (St Aidan’s Church)</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Harehills Rd (Top of Lambton View)</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Harehills Rd (BK Store)</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>

                </div>

              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default StaffShuttleForzaServicePage
